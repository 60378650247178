import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Button } from "rsuite";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

require("./AaJumbotron.scss");

const AaJumbotron = (props) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "red-lights-in-line-on-black-surface-158826.jpg" }) {
        childImageSharp {
          fixed(width: 800, height: 600) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  var jumbotronstyles = {
    // backgroundImage: `url(${this.props.featuredimage})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    position: 'relative',
    overflow: 'hidden',
  };
  var imageStyles = {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
  };
  var jcontainer = {
    backgroundColor: 'rgba(0,0,0,0.4)',
    position: "relative",
    zIndex: "100",
  };
  var jcontainerNoOverlay = {
    position: "relative",
    zIndex: "100",
  };
  return (
    <Jumbotron className="aaJumbotron" style={jumbotronstyles}>
      <Img style={imageStyles} fixed={props.featuredimage ? props.featuredimage : image.childImageSharp.fixed} />
      <div style={props.overlay ? jcontainer : jcontainerNoOverlay} className="jumbotron-container">
        <div className="container">

          <h1>{props.title}</h1>
          <p>{props.description}</p>
          {props.link ? (
            <p>
              <AniLink paintDrip hex="#359dd9" to={props.link}>
                <Button className="btn" >
                  Learn more →
                  </Button>
              </AniLink>
            </p>
          ) : (
              ""
            )}
        </div>
      </div>
    </Jumbotron>
  );
}

export default AaJumbotron;
